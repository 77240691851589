import {helper} from "./helper";
import * as ExcelJS from "exceljs";


const CSVHandler = {
    checkHeaders: (headers, expectedHeaders) => {
        const cleanHeaders = headers.map(header => header.trim().toLowerCase()); // Convertir en minuscules pour ignorer la casse

        const missingHeaders = [];
        const incorrectHeaders = [];

        // Vérifier les en-têtes manquants et incorrects
        expectedHeaders.forEach((expectedHeader) => {
            const expectedHeaderLowerCase = expectedHeader.toLowerCase(); // Convertir en minuscules pour ignorer la casse
            if (!cleanHeaders.includes(expectedHeaderLowerCase)) {
                missingHeaders.push(expectedHeader);
            } else if (cleanHeaders.indexOf(expectedHeaderLowerCase) !== cleanHeaders.lastIndexOf(expectedHeaderLowerCase)) {
                incorrectHeaders.push({
                    expected: expectedHeader,
                    found: headers[cleanHeaders.indexOf(expectedHeaderLowerCase)],
                });
            }
        });

        if (missingHeaders.length > 0 || incorrectHeaders.length > 0) {
            return {
                missingHeaders,
                incorrectHeaders,
            };
        }

        return true;
    },


    processCSVLines: async (patrimoineLibelle, csvLines, publishProcessMessage, publishErrorMessage) => {
        let containers = [];
        const latitudeRegex = /^-?([1-8]?[1-9]|[1-9]0)\.\d{1,25}$/
        const longitudeRegex = /^-?((([1]?[0-7][0-9])|([1]?[0-8][0])|([1-9]?[0-9])|(0?\.\d{1,25}))\.\d{1,25}|180\.0{1,25})$/;

        let totalLines = csvLines.length - 2;

        //Slice pour retirer les headers
        for (const csvLine of csvLines.slice(1, csvLines.length - 1)) {
            const index = csvLines.slice(1, csvLines.length - 1).indexOf(csvLine);
            const lineData = csvLine.split(';');
            publishProcessMessage(`Traitement de la ligne ${index + 1} / ${totalLines}`);
            try {
                let nomenclature = lineData[0].trim();
                let adresse = lineData[1].trim();
                let complementAdresse = lineData[2].trim();
                let commune = lineData[3].trim();
                let typeConteneur = lineData[4].trim();
                let marque = lineData[5].trim();
                let quantite = lineData[6].trim() || '1';
                let typeDechet = lineData[7].trim();
                let volume = lineData[8].trim();
                let latitude, longitude;
                if (lineData[9].includes(',')) {
                    // Latitude et longitude sont dans la même colonne, séparées par une virgule
                    [latitude, longitude] = lineData[9].split(',').map(coord => coord.trim());
                } else {
                    // Latitude et longitude sont dans des colonnes séparées
                    latitude = lineData[9].trim();
                    longitude = lineData[10].trim();
                }

                // Correction en cas d'inversion potentielle (MARCHE UNIQUEMENT POUR LA FRANCE)
                if ((latitude < 41 || latitude > 51) && (longitude >= 41 && longitude <= 51)) {
                    [latitude, longitude] = [longitude, latitude];
                }

                let hasError = false;

                // Vérifier si latitude/longitude sont absents et adresse est vide
                if (!latitude || !longitude) {
                    if (!adresse || !commune) {
                        publishErrorMessage(`Erreur dans la ligne ${index + 2}: L'adresse et la commune doivent être renseignées si latitude/longitude sont absents.`);
                        hasError = true;
                    } else {
                        let fullAdd = adresse + " " + complementAdresse + " " + commune;
                        try {
                            publishProcessMessage(`Calcul de la latitude/longitude pour la ligne ${index + 2}`);
                            const geometry = await helper.geocodeAddress(fullAdd);
                            console.log("conteneur " + nomenclature + " geocodée : " + JSON.stringify(geometry));
                            latitude = geometry.latitude;
                            longitude = geometry.longitude;
                        } catch (e) {
                            publishErrorMessage(`Impossible de déterminer latitude/longitude à partir de ${fullAdd}`);
                            hasError = true;
                        }
                    }
                } else if (latitude && longitude && (!adresse || !commune)) {
                    try {
                        publishProcessMessage(`Calcul de l'adresse et de la commune pour la ligne ${index + 2}`);

                        const addressComponents = await helper.reverseGeocode(parseFloat(latitude), parseFloat(longitude));

                        const decodedAdd = helper.extractAddressAndCommuneFromComponents(addressComponents);
                        commune = decodedAdd.commune;
                        adresse = decodedAdd.adresse;
                    } catch (e) {
                        publishErrorMessage(`Impossible de déterminer l'adresse et la commune à partir de latitude: ${latitude}, longitude: ${longitude}`);
                        hasError = true;
                    }
                } else if (!latitude && !longitude && !adresse && !commune) {
                    publishErrorMessage(`Erreur dans la ligne ${index + 2}: Aucune information de localisation fournie.`);
                    hasError = true;
                } else if (latitude && longitude) {
                    if (latitude.includes(',')) {
                        latitude = latitude.replace(',', '.');
                    }
                    if (longitude.includes(',')) {
                        longitude = longitude.replace(',', '.');
                    }

                    if (!latitudeRegex.test(latitude)) {
                        publishErrorMessage(`Erreur dans la ligne ${index + 2}: la latitude n'est pas valide`);
                        hasError = true;
                    }
                    if (!longitudeRegex.test(longitude)) {
                        publishErrorMessage(`Erreur dans la ligne ${index + 2}: la longitude n'est pas valide`);
                        hasError = true;
                    }

                }

                let normalizedTypeConteneur = null;
                // Vérifier si typeConteneur est vide
                if (!typeConteneur) {
                    publishErrorMessage(`Erreur dans la ligne ${index + 2}: type de conteneur doit être renseigné.`);
                    hasError = true;
                } else {
                    normalizedTypeConteneur = CSVHandler.mapConteneurType(typeConteneur);
                    if (!normalizedTypeConteneur) {
                        publishErrorMessage(`Erreur dans la ligne ${index + 2}: Le type de conteneur ${typeConteneur} n'est pas reconnu.`);
                        hasError = true;
                    }
                }

                let normalizedTypeDechet = null;
                // Vérifier si typeDechet est vide
                if (!typeDechet) {
                    publishErrorMessage(`Erreur dans la ligne ${index + 2}: type de déchet doit être renseigné.`);
                    hasError = true;
                } else {
                    normalizedTypeDechet = CSVHandler.mapTypeDechet(typeDechet);
                }

                // Vérifier si nomenclature est vide
                if (!nomenclature) {
                    publishErrorMessage(`Erreur dans la ligne ${index + 2}: nomenclature doit être renseignée.`);
                    hasError = true;
                    // Générer la nomenclature à partir du nom du patrimoine et un code aléatoire
                    // const randomCode = Math.floor(100000 + Math.random() * 900000);
                    // nomenclature = `${helper.normalizeString(patrimoineLibelle)}-${randomCode}`;
                }


                if (!hasError) {
                    // Calculer la latitude/longitude si nécessaire
                    if ((!latitude || !longitude) && adresse) {
                        // Effectuer les opérations pour déterminer la latitude/longitude à partir de l'adresse

                    }


                    let container = {
                        nomenclature: nomenclature,
                        adresse: adresse + " " + complementAdresse,
                        commune: commune,
                        conteneurType: normalizedTypeConteneur,
                        marque: marque,
                        volume: parseFloat(volume),
                        dechetType: normalizedTypeDechet,
                        latitude: String(latitude),
                        longitude: String(longitude),
                        quantite: parseInt(quantite),
                        ordreCsv: index + 1
                    }
                    containers.push(container)
                }
            } catch (e) {
                publishErrorMessage(`Erreur dans la ligne ${index + 2}: ${e}`)
            }


        }
        let quantite = 0;
        containers.forEach((c) => {
            quantite += c.quantite
        })
        publishProcessMessage(`Traitement terminé, nombre de lignes conformes : ${containers.length} sur un total de ${csvLines.length - 2} ; Nombre de points de collecte : ${containers.length} ; Nombre de conteneurs : ${quantite}`);
        return containers;
    },



    mapTypeDechet: (typeDechet) => {
        const mapping = {
            OM: ['OM', 'OMR', 'Ordure ménagère', 'Ordures ménagères', 'Ordure ménagère'],
            REC: ['Recyclé', 'REC', 'EMB', 'jaune'],
            VER: ['Verre', 'VER'],
            PAP: ['Papier', 'PAP'],
            BIO: ['Bio-déchet', 'Bio déchet', 'Bio déchets', 'BIO'],
            AUT: ['Autre', 'AUT'],
        };
        const normalizedTypeDechet = typeDechet.trim().toLowerCase();

        for (const key in mapping) {
            if (mapping.hasOwnProperty(key)) {
                const normalizedValues = mapping[key].map(value => value.trim().toLowerCase());
                if (normalizedValues.includes(normalizedTypeDechet)) {
                    return key;
                }
            }
        }

        return 'AUT'; // Valeur par défaut si aucune correspondance n'est trouvée
    },

    mapConteneurType: (typeConteneur) => {
        const mapping = {
            CA: ['Colonne Aérienne', 'CA', 'aerien', 'aerienne'],
            CE: ['Colonne Enterrée', "Colonne enterré", "CE", 'enterré', 'enterrée'],
            CSE: ['Colonne semi enterrée', 'Colonne semi-enterrée', 'Colonne semi enterré', 'Colonne semi-enterré', 'CSE', 'semi enterree', 'semi-enterre', 'SEMI-ENTERRÉ', 'semi enterre', 'semi-enterree'],
            AB: ['Abri bacs', 'Abri bac', 'Abri-bacs', 'Abri-bac', 'AB'],
            BAC: ['Bac', 'bacs'],
            AUT: ['Autre', 'AUT', 'autres']
        };
        const normalizedTypeConteneur = typeConteneur.trim().toLowerCase();

        for (const key in mapping) {
            if (mapping.hasOwnProperty(key)) {
                const normalizedValues = mapping[key].map(value => value.trim().toLowerCase());
                if (normalizedValues.includes(normalizedTypeConteneur)) {
                    return key;
                }
            }
        }

        return null; // Valeur par défaut si aucune correspondance n'est trouvée
    },


    /**
     * Grosso modo l'inverse de l'import CSV
     */
    exportPatrimoineToCSV: (patrimoine) => {
        const headers = ["Nomenclature", "Adresse", "Complement d'adresse", "Commune", "Type de conteneur", "Marque", "Quantite", "Type de dechet", "Volume", "Latitude", "Longitude"];
        let csvRows = [headers];
        if (patrimoine && !patrimoine.isUnipatrimoine) {
            let conteneurs = patrimoine.conteneurs;
            conteneurs.sort((a, b) => {
                if (a.ordreCsv === null) return 1; // Place a à la fin si ordreCsv est null
                if (b.ordreCsv === null) return -1; // Place b à la fin si ordreCsv est null
                return a.ordreCsv - b.ordreCsv;
            });
            for (let c of conteneurs) {
                let row = [
                    c.nomenclature,
                    c.adresse,
                    c.complementAdresse,
                    c.commune,
                    c.conteneurType,
                    c.marque,
                    c.quantite,
                    c.dechetType,
                    c.volume,
                    c.latitude,
                    c.longitude,
                ]
                csvRows.push(row)
            }
        }

        const csvString = csvRows.map(row => row.join(';')).join('\n');

        const downloadLink = document.createElement("a");
        downloadLink.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvString);
        downloadLink.download = patrimoine.libelle + ".csv"; // Nom du fichier à télécharger
        downloadLink.click();
    },

    /**
     * IMpression d'une tournée
     * On met les données sous forme de CSV pour que l'exploitant puisse l'imprimer pour son opérateur
     * == feuille de tournée papier pour les opérateurs
     * @param tournee :
     * @param nextOccurence :
     */
    exportTourneeToCSV: (tournee, nextOccurence) => {
        let csvRows = [];

        csvRows.push(["Client : ", tournee?.client?.libelle]);
        csvRows.push(["Cordonnées : "]);
        csvRows.push(["Tournée : ", tournee?.libelle]);
        csvRows.push(["Date : ", new Date(nextOccurence).toLocaleDateString("fr-FR")]);

        csvRows.push([]);

        csvRows.push(["Ordre", "Adresse", "Complement adresse", "Commune", "type de déchet", "type", "quantité", "Lavé"])

        //TRI CONTENEUR ICI :
        // PAr ordre de campagne si definit
        // SInon par ordre csv
        // Sinon par ID

        let i = 1;
        for (let c of tournee?.conteneurs) {
            csvRows.push(
                [
                    i,
                    c.adresse,
                    c.complementAdresse ?? "",
                    c.commune,
                    c.dechetType,
                    c.conteneurType,
                    c.quantite,
                    ""
                ]
            )
            ++i;
        }


        const csvString = csvRows.map(row => row.join(';')).join('\n');

        const downloadLink = document.createElement("a");
        downloadLink.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvString);
        downloadLink.download = tournee.libelle + ".csv"; // Nom du fichier à télécharger
        downloadLink.click();
    },

    exportTourneeToExcel: async (tournee, nextOccurence) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Tournee');

        // Style de bordure pour les cellules
        const borderStyle = {
            top: {style: "thin"},
            left: {style: "thin"},
            bottom: {style: "thin"},
            right: {style: "thin"}
        };

        // Ajouter des informations de base
        worksheet.addRow(["Client :", tournee?.client?.libelle]);
        worksheet.addRow(["Cordonnées :"]);
        worksheet.addRow(["Tournée :", tournee?.libelle]);
        worksheet.addRow(["Date :", new Date(nextOccurence).toLocaleDateString("fr-FR")]);
        worksheet.addRow([]);

        // Entêtes de colonne
        worksheet.addRow(["Ordre", "Adresse", "Complement adresse", "Commune", "type de déchet", "type", "quantité", "Lavé"]);

        if (Array.isArray(tournee.ordre) && tournee.ordre.length > 0) {
            const ordreMap = new Map(tournee.ordre.map((id, index) => [id, index]));

            tournee?.conteneurs?.sort((a, b) => {
                const idA = a['@id']?.split('/').pop(); // Extraire l'ID de l'IRI
                const idB = b['@id']?.split('/').pop();

                const indexA = ordreMap.has(idA) ? ordreMap.get(idA) : Infinity;
                const indexB = ordreMap.has(idB) ? ordreMap.get(idB) : Infinity;

                return indexA - indexB;
            });
        } else {
            // Tri par ordreCsv si aucun ordre spécifique n'est défini
            tournee?.conteneurs?.sort((a, b) => {
                if (a.ordreCsv === null) return 1;
                if (b.ordreCsv === null) return -1;
                return a.ordreCsv - b.ordreCsv;
            });
        }

        // Boucle sur les conteneurs
        let i = 1;
        for (let c of tournee?.conteneurs) {
            const rowData = [
                i,
                c.adresse,
                c.complementAdresse ?? "",
                c.commune,
                c.dechetType,
                c.conteneurType,
                c.quantite,
                ""
            ];

            const row = worksheet.addRow(rowData);
            row.eachCell({includeEmpty: true}, (cell) => {
                cell.border = borderStyle;
            });

            i++;
        }

        // Centrer le contenu de la première colonne à partir de la ligne 7
        for (let rowIndex = 7; rowIndex <= worksheet.rowCount; rowIndex++) {
            const row = worksheet.getRow(rowIndex);
            const cell = row.getCell(1); // 1ère colonne
            cell.alignment = {horizontal: 'center'};
        }

        // Ajuster la largeur des colonnes
        worksheet.columns.forEach(column => {
            let maxLength = 0;
            column.eachCell({includeEmpty: true}, cell => {
                let cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        //configuration de l'impression
        worksheet.pageSetup.orientation = 'landscape'; // Mode paysage
        worksheet.pageSetup.fitToPage = true; // Activer l'ajustement à la page
        worksheet.pageSetup.fitToWidth = 1; // Ajuster à toute les colonnes
        worksheet.pageSetup.fitToHeight = 0;


        // Sauvegarder le fichier
        const buffer = await workbook.xlsx.writeBuffer()

        // délenche le dl
        // Créer un Blob à partir du buffer
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        // Créer un lien pour le téléchargement
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = tournee.libelle + ".xlsx";

        document.body.appendChild(link);
        link.click();

        // Nettoyer et retirer le lien
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }


};


export default CSVHandler;
